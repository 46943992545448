const navBtn = document.getElementById('navBtn')
const navBtnClass = navBtn.getAttribute('class')

navBtn.addEventListener('click', () => {
  if (navBtn.getAttribute('class') == navBtnClass) {
    navBtn.setAttribute('class', navBtnClass + ' is-open')
  } else {
    navBtn.setAttribute('class', navBtnClass)
  }
})
