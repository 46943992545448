let popUpBtns = document.getElementsByClassName('PopUpList__itemBtn')
let closeBtns = document.getElementsByClassName('PopUpList__closeBtn')
let popUpItems = document.getElementsByClassName('PopUpList__item')
for (let i = 0; i < popUpBtns.length; i++) {
  popUpBtns[i].addEventListener('click', () => {
    popUpItems[i].setAttribute('class', popUpItems[i].getAttribute('class') + ' open')
  })
  closeBtns[i].addEventListener('click', () => {
    var regExp = new RegExp(' open', "g");
    popUpItems[i].setAttribute('class', popUpItems[i].getAttribute('class').replace(regExp, ""))
  })
}
