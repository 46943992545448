
// スムーズスクロール
const MoveTo = require('moveTo')
window.MoveTo = MoveTo;
let scrollTop;

const moveto = new MoveTo({
  tolerance: 0,
  duration: 800,
  easing: 'easeOutQuart'
})
let aTagArry = document.querySelectorAll('a[href^="#"]')
for (let i = 0; i < aTagArry.length; i++) {
  moveto.registerTrigger(aTagArry[i]);
}


//スクロールでフッターバーのフェイドイン
const fixedbar = document.getElementById('fixedbar')
let classFixedbar = fixedbar.getAttribute('class')

window.addEventListener('scroll', () => {
  scrollTop = document.body.scrollTop || document.documentElement.scrollTop

  if (window.innerWidth < 767) {
    if (scrollTop > 300) {
      fixedbar.setAttribute('class', classFixedbar + ' is-show')
    } else {
      fixedbar.setAttribute('class', classFixedbar)
    }

  } else {
    fixedbar.removeAttribute('style')
  }
})
